/* global HTMLElement, window, CustomEvent */

class QuizAnswer extends HTMLElement {

	constructor() {
		super();
		this._setupClickListener();
	}

	connectedCallback() {
		// Make sure no answer is selected; happens in certain browsers when
		// navigating back or reloading page
		this.querySelector('input').checked = false;
		this._dispatchRegisterEvent();
	}


	/**
	* Directly called from quiz-question when any answer is given.
	*/
	disable() {
		this.querySelector('input').setAttribute('disabled', '');
	}


	_setupClickListener() {
		this.querySelector('input').addEventListener('change', this._handleInputChange.bind(this));
	}


	/**
	* Handler for «click» on answer 
	*/
	_handleInputChange(ev) {
		ev.preventDefault();
		const correct = this.hasAttribute('is-correct')
			, answerId = this.getAttribute('answer-id');
		const event = new CustomEvent('select-answer', { detail: { correct: correct, answerId: answerId }, bubbles: true });
		this.dispatchEvent(event);
	}

	/**
	* Dispatch register-answer when element is added
	*/
	_dispatchRegisterEvent() {
		const event = new CustomEvent('register-answer', { detail: { element: this }, bubbles: true });
		console.log('QuizAnswer: Dispatch register-answer event');
		this.dispatchEvent(event);
	}


}

window.customElements.define('quiz-answer', QuizAnswer);